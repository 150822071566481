// Loops through all links on the page and adds UTM params

export function addUTMParams() {
  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search)

    const utmSource = urlParams.get('utm_source') || ''
    const utmMedium = urlParams.get('utm_medium') || ''
    const utmCampaign = urlParams.get('utm_campaign') || ''
    const utmContent = urlParams.get('utm_content') || ''

    if (utmSource || utmMedium || utmCampaign || utmContent) {
      const links = document.querySelectorAll('a[href]')

      for (const outboundLink of links) {
        const url = new URL((outboundLink as HTMLAnchorElement).href, window.location.origin)

        if (utmSource) {
          url.searchParams.set('utm_source', utmSource)
        }
        if (utmMedium) {
          url.searchParams.set('utm_medium', utmMedium)
        }
        if (utmCampaign) {
          url.searchParams.set('utm_campaign', utmCampaign)
        }
        if (utmContent) {
          url.searchParams.set('utm_content', utmContent)
        }

        ;(outboundLink as HTMLAnchorElement).href = url.toString()
      }
    }
  }
}
