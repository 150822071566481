import {useEffect, useState} from 'react'

export function useUTMParams() {
  const [utmParams, setUTMParams] = useState({})

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const params = {}

    for (const [key, value] of searchParams.entries()) {
      if (key === 'utm_source' || key === 'utm_medium' || key === 'utm_campaign') {
        params[key] = value
      }
    }

    setUTMParams(params)

    if (Object.keys(params).length > 0) {
      localStorage.setItem('utmParams', JSON.stringify(params))
    } else {
      const storedParams = localStorage.getItem('utmParams')

      if (storedParams) {
        setUTMParams(JSON.parse(storedParams))
      }
    }
  }, [])

  const getUTMParam = paramName => {
    return utmParams[paramName] || ''
  }

  return {
    getUTMParam,
  }
}
