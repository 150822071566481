import React, {createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useEffect, useState} from 'react'

export enum Timezones {
  PT = 'America/Los_Angeles',
  ET = 'America/New_York',
  JST = 'Asia/Tokyo',
  IST = 'Asia/Kolkata',
  AEDT = 'Australia/Sydney',
  CEST = 'Europe/Paris',
  BST = 'Europe/London',
}

export enum TimezoneLocale {
  PT = 'en-US',
  ET = 'en-US',
  JST = 'ja-JP',
  IST = 'en-US',
  CEST = 'en-US',
  BST = 'en-US',
  AEDT = 'en-US',
}

export const timezones = Object.keys(Timezones) as (keyof typeof Timezones)[]

export type TimezoneCodes = keyof typeof Timezones

type TimezoneContextType = {
  timezone: string
  setTimezone: (timezone: TimezoneCodes) => void
}

export const TimezoneContext = createContext<TimezoneContextType>({
  timezone: timezones[0],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTimezone: () => {},
})

export const TimezoneProvider = ({children}: PropsWithChildren) => {
  const [timezone, setTimezone] = useState(timezones[0])

  useEffect(() => {
    const storedTimezone = localStorage.getItem('timezone')
    if (storedTimezone) {
      setTimezone(storedTimezone as TimezoneCodes)
    }
  }, [])

  const handleSetTimezone = (newTimezone: TimezoneCodes) => {
    localStorage.setItem('timezone', newTimezone)
    setTimezone(newTimezone)
  }

  return (
    <TimezoneContext.Provider value={{timezone, setTimezone: handleSetTimezone}}>{children}</TimezoneContext.Provider>
  )
}

export const useTimezone = () => {
  const {timezone, setTimezone} = useContext(TimezoneContext)

  return {timezone, setTimezone}
}
