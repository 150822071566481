import React, {useEffect} from 'react'
import Head from 'next/head'
import type {AppProps} from 'next/app'
import {useRouter} from 'next/router'
import Script from 'next/script'
import {SubdomainNavBar, ThemeProvider} from '@primer/react-brand'

import '@primer/react-brand/lib/css/main.css'
import '@primer/react-brand/fonts/fonts.css'

import {TimezoneProvider} from '../stores/TimezoneStore'
import {useUTMParams} from '../hooks/useUTMParams'
import {addUTMParams} from '../fn/addUTMParams'

import '../styles/globals.css'

type AnalyticsClient = {
  sendPageView: (context?: Record<string, unknown>) => void
  sendEvent: (type: string, context: Record<string, unknown>) => void
}

type WindowWithAnalytics = Window & {
  _ha?: AnalyticsClient
}

export default function App({Component, pageProps}: AppProps) {
  const router = useRouter()
  useUTMParams()

  const handleRouteChange = () => {
    ;(window as WindowWithAnalytics)._ha?.sendPageView()
  }

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }

    // This should only mount once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const classPatternPrefix = 'page-is-'

    // remove all classes that start with `page-is-`
    document.body.classList.remove(...Array.from(document.body.classList).filter(c => c.startsWith(classPatternPrefix)))

    // add the new class
    document.body.classList.add(`${classPatternPrefix}${Component.name.toLowerCase()}`)
  }, [Component.name])

  useEffect(() => {
    document.querySelector('body')?.classList.add('is-ready')
  }, [Component])

  // Loops through all `<a>` and adds UTM params
  useEffect(() => {
    addUTMParams()
  }, [])

  const description =
    // eslint-disable-next-line i18n-text/no-en
    'Join GitHub product leaders as they discuss how to transform your business by using an integrated developer experience to accelerate productivity, efficiency, and collaboration.'

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    description,
    headline: 'GitHub Galaxy',
    image: 'https://galaxy.github.com/og-image-2024.jpg',
    name: 'GitHub Galaxy',
    url: '/',
  }

  return (
    <>
      <Head>
        <meta httpEquiv="content-language" content="en-us" />
        <meta name="robots" content="index,follow" />

        <meta property="og:url" content="https://galaxy.github.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://galaxy.github.com/og-image-2024.jpg" />
        <meta property="og:image:alt" content="GitHub Galaxy" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="GitHub Galaxy" />
        <meta property="og:title" content="GitHub Galaxy" />
        <meta
          property="og:description"
          content="Learn from business innovators on how to increase developer satisfaction—and happiness—to fuel business growth."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@github" />
        <meta name="twitter:creator" content="@GitHub" />

        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ab3f8b" />
        <meta name="apple-mobile-web-app-title" content="GitHub Galaxy" />
        <meta name="application-name" content="GitHub Galaxy" />
        <meta name="msapplication-TileColor" content="#ab3f8b" />
        <meta name="theme-color" content="#151920" />
        <link rel="icon" href="/favicon.ico" />

        <meta name="ha-url" content="https://collector.githubapp.com/github-galaxy/collect" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(structuredData)}} />
      </Head>
      <ThemeProvider colorMode="dark">
        <TimezoneProvider>
          <div className="vertical-borders"></div>
          <SubdomainNavBar title="GitHub Galaxy" className="global-nav"></SubdomainNavBar>
          <Component {...pageProps} />
        </TimezoneProvider>
      </ThemeProvider>
      <Script src="https://analytics.githubassets.com/hydro-marketing.min.js" strategy="afterInteractive" />
    </>
  )
}
